// import { GoogleLogo } from '@app/assets/icons';
import Config from '@/config';
import AuthService from '@/services/auth.service';
import { sentryCaptureException } from '@/utility';
import React from 'react';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';

const clientId = Config.SIGNIN_CLIENT_ID;

interface IProps {
  onSuccessLogin: () => void;
}

const GoogleLoginButton: React.FC<IProps> = (props) => {
  const [disabled, setDisabled] = React.useState(false);
  const handleSuccess = async (data: GoogleLoginResponse) => {
    setDisabled(true);
    try {
      const authService = new AuthService();
      const authInfo = await authService.googleLogin({
        tokenId: data.tokenId,
      });

      authService.saveAuth(authInfo);
      props.onSuccessLogin();
      setDisabled(false);
    } catch (error) {
      // do nothing
      sentryCaptureException(error);
      alert('Unable to login with Google');
    }
  };

  const handleError = () => {
    // do nothing
  };

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Login"
      onSuccess={handleSuccess}
      onFailure={handleError}
      cookiePolicy={'single_host_origin'}
      render={(renderProps) => {
        return (
          <button
            type="button"
            className="mt-2 text-white py-2 px-2 rounded-sm"
            style={{ backgroundColor: disabled ? '#d1d5db' : '#dd4b39' }}
            disabled={disabled || renderProps.disabled}
            onClick={renderProps.onClick}
          >
            Sign in with Google
          </button>
        );
      }}
    />
  );
};

export default GoogleLoginButton;
